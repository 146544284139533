import parse from 'date-fns/parse';
import format from 'date-fns/format';
import add from 'date-fns/add';
import isWithinInterval from 'date-fns/isWithinInterval';
import React, { useEffect, useState } from 'react';

export const IS_DEV = process.env.NODE_ENV !== 'production';
export const MAIL_URL = IS_DEV
	? 'https://localhost/krokowski-fueller.de/mailer/'
	: '/.netlify/functions/sendMail';

export const OpeningTimes = {
	1: {
		label: 'Montag',
		times: [['08:00', '13:00']],
	},
	2: {
		label: 'Dienstag',
		times: [['08:00', '13:30']],
	},
	3: {
		label: 'Mittwoch',
		times: [['08:00', '13:00']],
	},
	4: {
		label: 'Donnerstag',
		times: [['08:00', '13:30']],
	},
	5: {
		label: 'Freitag',
		times: [['08:00', '12:00']],
	},
};

export const VacationTimes = [
	{
		phone: {
			start: '17.03.2025',
			end: '02.04.2025',
		},
		contact: {
			start: '17.03.2025',
			end: '01.04.2025',
		},
		start: '17.03.2025',
		end: '02.04.2025',
		text: (
			<div>
				<p>
					unsere Praxis bleibt bis einschließlich 02.04.2025
					geschlossen.
				</p>
				<p>
					In{' '}
					<strong>ausschließlich dermatologischen Notfällen</strong>{' '}
					wenden Sie sich bitte an:
				</p>
				<p>
					MVZ Scin Center Cassel GmbH
					<br />
					Frau Dr. Knittl, Tel.: 0561-104467
				</p>
				<p>
					Am 02.04.2025 können Sie uns ausschließlich von 8:30 bis
					11:30 Uhr telefonisch erreichen.
				</p>
			</div>
		),
	},
];

export const getVacationData = () => {
	let vacationData = null;
	let isOpen = null;
	let phoneOpen = null;
	let contactOpen = null;
	let vacationEndDate = null;

	VacationTimes.forEach((vacationTime) => {
		// Check if we are currently in vacation duration
		const start = parse(vacationTime.start, 'dd.MM.yyyy', new Date());
		const end = parse(vacationTime.end, 'dd.MM.yyyy', new Date());
		const today = new Date();

		if (
			isWithinInterval(today, {
				start,
				end,
			})
		) {
			vacationData = vacationTime;
			isOpen = false;
			vacationEndDate = format(end, 'dd.MM.yyyy');

			// We are in an vacation interval. Check if phone is open
			// @Note: Phone times can be different than opening times!
			if (vacationTime.phone) {
				const phoneStart = parse(
					vacationTime.phone.start,
					'dd.MM.yyyy',
					new Date(),
				);
				const phoneEnd = parse(
					vacationTime.phone.end,
					'dd.MM.yyyy',
					new Date(),
				);

				if (
					isWithinInterval(today, {
						start: phoneStart,
						end: phoneEnd,
					})
				) {
					// Phone is also vacation
					phoneOpen = false;
				} else {
					phoneOpen = true;
				}
			}

			if (vacationTime.contact) {
				const contactStart = parse(
					vacationTime.contact.start,
					'dd.MM.yyyy',
					new Date(),
				);
				const contactEnd = parse(
					vacationTime.contact.end,
					'dd.MM.yyyy',
					new Date(),
				);

				if (
					isWithinInterval(today, {
						start: contactStart,
						end: contactEnd,
					})
				) {
					contactOpen = false;
				} else {
					contactOpen = true;
				}
			}
		}
	});

	return {
		vacationData,
		isOpen,
		phoneOpen,
		vacationEndDate,
		contactOpen,
	};
};
